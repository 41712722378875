// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Overlay_overlayWrap__XcC1c {
    position: relative;
}

.Overlay_overlay__U7ZBy {
    position: absolute;
    inset: 0px;
    z-index: 10;
}

.Overlay_overlayBg__wA1lF {
    position: absolute;
    border-radius: 0.25rem;
    background-color: #f8f9fa;
    inset: 0px;
    opacity: 0.85;
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
}

.Overlay_overlayContainer__YlFsk {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}`, "",{"version":3,"sources":["webpack://./src/components/common/Overlay/Overlay.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,yBAAyB;IACzB,UAAU;IACV,aAAa;IACb,kCAA0B;YAA1B,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,4CAA4C;AAChD","sourcesContent":[".overlayWrap {\n    position: relative;\n}\n\n.overlay {\n    position: absolute;\n    inset: 0px;\n    z-index: 10;\n}\n\n.overlayBg {\n    position: absolute;\n    border-radius: 0.25rem;\n    background-color: #f8f9fa;\n    inset: 0px;\n    opacity: 0.85;\n    backdrop-filter: blur(2px);\n}\n\n.overlayContainer {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translateX(-50%) translateY(-50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlayWrap": `Overlay_overlayWrap__XcC1c`,
	"overlay": `Overlay_overlay__U7ZBy`,
	"overlayBg": `Overlay_overlayBg__wA1lF`,
	"overlayContainer": `Overlay_overlayContainer__YlFsk`
};
export default ___CSS_LOADER_EXPORT___;
