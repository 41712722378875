// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bulk-modal-title {
  font-size: 24px;
  font-weight: 700;
  margin-right: 1.5rem;
}
.bulk-modal-subtitle {
  color: #676767;
  margin: 0.5rem 0 0;
  width: -webkit-fit-content;
  width: fit-content;
}
.bulk-modal-checkbox {
  display: flex;
  margin: 0 12px;
}
.bulk-modal-deadline-label {
  margin-right: 16px;
}
.bulk-modal-modify {
  background-color: #479bd2;
  padding: 4px 16px;
  font-weight: 700;
  color: "#fff";
  border-radius: 1rem;
  cursor: pointer;
  border: 0;
  font-size: 16px;
}
.bulk-modal-cancel {
  background-color: "#f0f0f0";
  padding: 4px 16px;
  font-weight: 700;
  margin-right: 1rem;
  border-radius: 1rem;
  cursor: "pointer";
  border: 0;
  font-size: 16px;
}
.bulk-modal-input-container {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.bulk-modal-container {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
}`, "",{"version":3,"sources":["webpack://./src/styles/BulkEmailModal.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,gBAAA;EACA,oBAAA;AAAR;AAGI;EACI,cAAA;EACA,kBAAA;EACA,0BAAA;EAEA,kBAAA;AADR;AAII;EACI,aAAA;EACA,cAAA;AAFR;AAKI;EACI,kBAAA;AAHR;AAMI;EACI,yBAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;EACA,eAAA;AAJR;AAOI;EACI,2BAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;EACA,SAAA;EACA,eAAA;AALR;AAQI;EACI,aAAA;EACA,mBAAA;EACA,cAAA;AANR;AASI;EACI,aAAA;EACA,sBAAA;EACA,iBAAA;AAPR","sourcesContent":[".bulk-modal {\n    &-title {\n        font-size: 24px;\n        font-weight: 700;\n        margin-right: 1.5rem;\n    }\n\n    &-subtitle {\n        color: #676767;\n        margin: 0.5rem 0 0;\n        width: -webkit-fit-content;\n        width: -moz-fit-content;\n        width: fit-content;\n    }\n\n    &-checkbox {\n        display: flex;\n        margin: 0 12px;\n    }\n\n    &-deadline-label {\n        margin-right: 16px;\n    }\n\n    &-modify {\n        background-color: #479bd2;\n        padding: 4px 16px;\n        font-weight: 700;\n        color: '#fff';\n        border-radius: 1rem;\n        cursor: pointer;\n        border: 0;\n        font-size: 16px;\n    }\n\n    &-cancel {\n        background-color: '#f0f0f0';\n        padding: 4px 16px;\n        font-weight: 700;\n        margin-right: 1rem;\n        border-radius: 1rem;\n        cursor: 'pointer';\n        border: 0;\n        font-size: 16px;\n    }\n\n    &-input-container {\n        display: flex;\n        align-items: center;\n        margin: 10px 0;\n    }\n\n    &-container {\n        display: flex;\n        flex-direction: column;\n        padding-top: 16px\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
