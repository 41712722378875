import React, { useState } from "react";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { Menu } from "@mui/material";

const decimalToDateTime = (noOfDays) => {
    // convert noOfDays to milliseconds and, convert that milliseconds to 2022-04-17T15:30 this format
    const decimalInDateTime = dayjs(
        new Date().getTime() + noOfDays * 24 * 60 * 60 * 1000
    );
    return decimalInDateTime;
};

const DateTimeComponent = (props) => {
    const { value, onChange, onClose } = props;
    const noOfDaysInDateTime = decimalToDateTime(value);

    const handleAccept = (value) => {
        console.log(value);
        const currentDate = new Date();
        const dateTimeInDecimal =
            value.diff(currentDate, "millisecond") / (24 * 60 * 60 * 1000);

        onChange(dateTimeInDecimal);
        onClose();
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDateTimePicker
                disablePast
                orientation="landscape"
                defaultValue={dayjs(new Date())}
                value={noOfDaysInDateTime}
                onAccept={handleAccept}
                slotProps={{
                    actionBar: {
                        actions: ["accept"],
                    },
                }}
            />
        </LocalizationProvider>
    );
};

function CustomDateTimePicker(props) {
    const { children, value, onChange } = props;
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClosePopover = () => {
        console.log("closed");
        setOpen(false);
        setAnchorEl(null);
    };

    return (
        <div>
            <div
                onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    setOpen(true);
                }}
            >
                {children}
            </div>

            <Menu
                anchorEl={anchorEl}
                open={open}
                id="menu"
                onClose={handleClosePopover}
            >
                <DateTimeComponent
                    value={value}
                    onChange={onChange}
                    onClose={handleClosePopover}
                />
            </Menu>
        </div>
    );
}

DateTimeComponent.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
};

CustomDateTimePicker.propTypes = {
    children: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.func,
};

export default CustomDateTimePicker;
